var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dialog',{on:{"cancel":_vm.cancelDialog}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.params.title || _vm.$t('cloudenv.text_364')))]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('dialog-selected-tips',{staticClass:"mt-3",attrs:{"name":_vm.params.name,"count":_vm.params.data.length,"action":_vm.params.action}}),(_vm.params.columns && _vm.params.columns.length)?_c('dialog-table',{attrs:{"data":_vm.params.data,"columns":_vm.params.columns.slice(0, 3)}}):_vm._e(),_c('a-form',_vm._b({attrs:{"form":_vm.form.fc}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',{attrs:{"label":_vm.$t('common.sync_mode'),"help":_vm.$t('common.increment_sync_tip')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sync_mode',
            {
              initialValue: 'full'
            }
          ]),expression:"[\n            'sync_mode',\n            {\n              initialValue: 'full'\n            }\n          ]"}]},[_c('a-radio-button',{attrs:{"value":"full"}},[_vm._v(_vm._s(_vm.$t('common.full_sync')))]),_c('a-radio-button',{attrs:{"value":"increment"}},[_vm._v(_vm._s(_vm.$t('common.increment_sync')))])],1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('common.full_sync_resource_type')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'resource',
            {
              initialValue: ['all'],
              rules: [{ required: true, message: _vm.$t('cloudenv.text_284', [_vm.$t('common.full_sync_resource_type')]) }]
            },
          ]),expression:"[\n            'resource',\n            {\n              initialValue: ['all'],\n              rules: [{ required: true, message: $t('cloudenv.text_284', [$t('common.full_sync_resource_type')]) }]\n            },\n          ]"}],attrs:{"mode":"multiple"},on:{"change":_vm.handleResourceChange}},_vm._l((_vm.resources),function(v){return _c('a-select-option',{key:v,attrs:{"value":v}},[_vm._v(" "+_vm._s(v === 'all' ? _vm.$t('cloudenv.text_297') : _vm.getResourceI18n(v))+" ")])}),1)],1)],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleConfirm}},[_vm._v(_vm._s(_vm.$t("dialog.ok")))]),_c('a-button',{on:{"click":_vm.cancelDialog}},[_vm._v(_vm._s(_vm.$t('dialog.cancel')))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }