export const colors = [
  '#1a9bfc',
  '#99da69',
  '#fa704d',
  '#34bfa3',
  '#E3E36A',
  '#6C49B8',
  '#D09E88',
  '#ABD0CE',
  '#ef5285',
  '#8283a7',
  '#f100e5',
  '#004e66',
]
