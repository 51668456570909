<template>
  <base-side-page
    @cancel="cancelSidePage"
    :title="$t('k8s.text_376')"
    icon="res-k8s-rbacclusterrole"
    :res-name="detailData.name"
    :current-tab="params.windowData.currentTab"
    :tabs="detailTabs"
    :loaded="loaded"
    @tab-change="handleTabChange">
    <template v-slot:actions>
      <actions
        :options="singleActions"
        :row="detailData"
        button-type="link"
        button-size="small" />
    </template>
    <component
      :is="params.windowData.currentTab"
      :data="detailData"
      :rules="detailData.rules"
      resource="rbacclusterroles"
      :serverColumns="columns"
      :id="listId"
      :res-id="data.id"
      :getParams="getParams"
      :on-manager="onManager"
      @refresh="refresh"
      @single-refresh="singleRefresh"
      @tab-change="handleTabChange" />
  </base-side-page>
</template>

<script>
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'
import K8sRbacclusterroleDetail from './Detail'
import SourceInformationSidepage from '@K8S/sections/SourceInformationSidepage'
import SidePageMixin from '@/mixins/sidePage'
import WindowsMixin from '@/mixins/windows'
import Actions from '@/components/PageList/Actions'
import RoleRulesSidepage from '@K8S/sections/RoleRulesSidepage'

export default {
  name: 'K8SRbacclusteroleSidePage',
  components: {
    Actions,
    K8sRbacclusterroleDetail,
    SourceInformationSidepage,
    RoleRulesSidepage,
  },
  mixins: [SidePageMixin, WindowsMixin, ColumnsMixin, SingleActionsMixin],
  data () {
    return {
      detailTabs: [
        { label: this.$t('k8s.text_217'), key: 'k8s-rbacclusterrole-detail' },
        { label: this.$t('k8s.text_378'), key: 'role-rules-sidepage' },
        { label: this.$t('k8s.text_219'), key: 'source-information-sidepage' },
        { label: this.$t('compute.text_240'), key: 'event-drawer' },
      ],
    }
  },
  computed: {
    getParams () {
      return null
    },
    listId () {
      switch (this.params.windowData.currentTab) {
        case 'event-drawer':
          return 'EventListForK8SRbacclusteroleSidePage'
        default:
          return ''
      }
    },
  },
}
</script>
