<template>
  <div>network-list{{ a }}</div>
</template>

<script>
export default {
  name: 'NetworkList',
  data () {
    return {
      a: 'aaa',
    }
  },
}
</script>

<style>

</style>
