import { render, staticRenderFns } from "./MonitorAlertNotify.vue?vue&type=template&id=2f5de406&"
import script from "./MonitorAlertNotify.vue?vue&type=script&lang=js&"
export * from "./MonitorAlertNotify.vue?vue&type=script&lang=js&"
import style0 from "./MonitorAlertNotify.vue?vue&type=style&index=0&id=2f5de406&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports