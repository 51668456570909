import { render, staticRenderFns } from "./SetupNotifyChannel.vue?vue&type=template&id=529b1156&scoped=true&"
import script from "./SetupNotifyChannel.vue?vue&type=script&lang=js&"
export * from "./SetupNotifyChannel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529b1156",
  null
  
)

export default component.exports