var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isAdminMode && !_vm.isDomainMode)?[_c('div',{staticStyle:{"margin-bottom":"24px"}},[_vm._v(_vm._s(_vm.projectData.label))])]:_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":12}},[(_vm.isAdminMode && _vm.l3PermissionEnable)?_c('a-form-item',{class:{ 'mb-0': _vm.mb0 },attrs:{"wrapperCol":{ span: 24 }}},[_c('base-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorators.domain),expression:"decorators.domain"}],ref:"domain",attrs:{"resource":"domains","remote":"","is-default-select":_vm.isDefaultSelect,"params":_vm.domainParams,"select-props":{
            allowClear: _vm.allowClear,
            labelInValue: _vm.labelInValue,
            placeholder: _vm.$t('rules.domain'),
            dropdownClassName: 'oc-select-dropdown',
            labelInValueKeyName: 'key',
          }},on:{"change":_vm.domainChange,"update:resList":_vm.updateDomainList},scopedSlots:_vm._u([{key:"optionLabelTemplate",fn:function({ item }){return [_c('span',{staticClass:"text-color-secondary option-prefix"},[_vm._v(_vm._s(_vm.$t('dictionary.domain'))+": ")]),_vm._v(_vm._s(item.name)+" ")]}}],null,false,2526107723)})],1):_vm._e()],1),_c('a-col',{attrs:{"span":(_vm.isAdminMode && _vm.l3PermissionEnable) ? 12 : 24}},[_c('a-form-item',{class:{ 'mb-0': _vm.mb0 },attrs:{"wrapperCol":{ span: 24 }}},[_c('base-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorators.project),expression:"decorators.project"}],ref:"project",attrs:{"resource":"projects","remote":"","is-default-select":_vm.isDefaultSelect,"params":_vm.projectParams,"select-props":{
            allowClear: _vm.allowClear,
            labelInValue: _vm.labelInValue,
            placeholder: _vm.$t('rules.project'),
            dropdownClassName: 'oc-select-dropdown',
            labelInValueKeyName: 'key',
          },"beforeDefaultSelectCallBack":_vm.beforeProjectDefaultSelectCallBack},on:{"change":_vm.projectChange,"update:resList":_vm.updateProjectList},scopedSlots:_vm._u([{key:"optionLabelTemplate",fn:function({ item }){return [_c('span',{staticClass:"text-color-secondary option-prefix"},[_vm._v(_vm._s(_vm.$t('dictionary.project'))+": ")]),_vm._v(_vm._s(item.name)+" ")]}}])})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }