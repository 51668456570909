<template>
  <base-side-page
    @cancel="cancelSidePage"
    :title="$t('dictionary.instancegroup')"
    icon="res-instancegroup"
    :res-name="detailData.name"
    :current-tab="params.windowData.currentTab"
    :tabs="detailTabs"
    :loaded="loaded"
    @tab-change="handleTabChange">
    <template v-slot:actions>
      <actions :options="singleActions" :row="detailData" button-type="link" button-size="small" />
    </template>
    <component
      :is="params.windowData.currentTab"
      :data="detailData"
      :res-id="detailData.id"
      :id="listId"
      :on-manager="onManager"
      :server-columns="columns"
      @side-page-trigger-handle="sidePageTriggerHandle"
      @init-side-page-tab="initSidePageTab"
      @refresh="refresh"
      @single-refresh="singleRefresh"
      @tab-change="handleTabChange" />
  </base-side-page>
</template>

<script>
import SidePageMixin from '@/mixins/sidePage'
import WindowsMixin from '@/mixins/windows'
import Actions from '@/components/PageList/Actions'
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'
import InstanceGroupDetail from './Detail'
import VMInstanceListForInstanceGroup from './VMInstanceListForInstanceGroup'
import VipNetworkListForInstanceGroup from './VIPList'

export default {
  name: 'InstanceGroupSidePage',
  components: {
    Actions,
    InstanceGroupDetail,
    VMInstanceListForInstanceGroup,
    VipNetworkListForInstanceGroup,
  },
  mixins: [SidePageMixin, WindowsMixin, ColumnsMixin, SingleActionsMixin],
  data () {
    return {
      detailTabs: [
        { label: this.$t('compute.text_238'), key: 'instance-group-detail' },
        { label: this.$t('common_460', [this.$t('dictionary.server')]), key: 'v-m-instance-list-for-instance-group' },
        { label: 'VIP', key: 'vip-network-list-for-instance-group' },
        { label: this.$t('compute.text_240'), key: 'event-drawer' },
      ],
    }
  },
  computed: {
    listId () {
      switch (this.params.windowData.currentTab) {
        case 'event-drawer':
          return 'EventListForBaremetalSidepage'
        default:
          return ''
      }
    },
  },
}
</script>
