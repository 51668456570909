import { render, staticRenderFns } from "./MergeMirror.vue?vue&type=template&id=368aaadf&scoped=true&"
import script from "./MergeMirror.vue?vue&type=script&lang=js&"
export * from "./MergeMirror.vue?vue&type=script&lang=js&"
import style0 from "./MergeMirror.vue?vue&type=style&index=0&id=368aaadf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368aaadf",
  null
  
)

export default component.exports